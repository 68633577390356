<template>
  <div>
    <XyeaFormEdit
      icon="doctor"
      url="doctor/"
      entityName="Doctor"
      :form="form"
      :id="form.doctorId"
      @close="$emit('close')"
      @add="docAdded"
      @update="selectDoc"
      :dialog="true"
      :loadOnOpen="false"
    >
      <b-field label="Name" horizontal>
        <person-title v-model="form.title" />
        <b-input v-model="form.firstName" placeholder="First Name" required ref="firstName"> </b-input>
        <b-input v-model="form.lastName" placeholder="Last Name" required> </b-input>
      </b-field>
      <b-field label="Practice" horizontal>
        <b-input v-model="form.practiceName" required ref="practice"> </b-input>
      </b-field>
      <b-field label="Email" horizontal>
        <b-input v-model="form.email" type="email" required />
      </b-field>
      <phone-edit
        label="Phone"
        :required="true"
        :phoneNumber="form.workNumber"
        :countryCode="form.workCountry"
        @updatePhone="updateWorkPhone"
        class="field"
        ref="workNumber"
      />
      <address-edit v-model="form" class="field" />
    </XyeaFormEdit>
  </div>
</template>

<script>
import AddressEdit from "@/components/address/AddressEdit";
import PersonTitle from "@/components/profile/PersonTitle";
import XyeaFormEdit from "@/components/XyeaFormEdit";
import PhoneEdit from "@/components/profile/PhoneEdit";
import PhoneMixin from "@/mixins/phoneMixin";

export default {
  components: { AddressEdit, PersonTitle, XyeaFormEdit, PhoneEdit },
  mixins: [PhoneMixin],
  props: ["gp", "gpName"],

  data() {
    return {
      isLoading: false,
      form: this.getClearFormObject()
    };
  },
  methods: {
    getClearFormObject() {
      return {
        doctorId: 0,
        title: PersonTitle.values.dr,
        firstName: null,
        lastName: null,
        practiceName: null,
        email: null,
        organisation: null,
        address1: null,
        address2: null,
        city: null,
        county: null,
        country: null,
        postcode: null,
        workNumber: null,
        workCountry: null
      };
    },
    docAdded(data) {
      // Add to Vuex store and send update back to GP Select
      this.$store.dispatch("doctors/addDoc", data);
      this.selectDoc(data);
    },
    selectDoc(data) {
      this.$emit("selectDoc", data);
    }
  },
  mounted() {
    if (this.gp && this.gp.doctorId) this.form = { ...this.gp };
    else if (this.gpName) {
      // get default name from previous gpName
      const words = this.gpName.split(" ");
      if (words[0] && words[0].toLowerCase() === PersonTitle.values.dr.toLowerCase()) words.shift();
      if (words[0]) this.form.firstName = words[0];
      if (words[1]) this.form.lastName = words[1];
      this.$refs.practice.focus();
    } else {
      this.$refs.firstName.focus();
    }
  }
};
</script>
