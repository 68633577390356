<template>
  <b-field label="Doctor / GP" horizontal class="label-medium">
    <div class="columns is-mobile">
      <b-autocomplete
        class="column"
        name="doctor"
        v-model="gpName"
        field="fullName"
        ref="autocomplete"
        @blur="blur"
        :data="filteredGPList"
        placeholder="Search / Select / Add GP"
        @select="selectGp"
        clearable
        open-on-focus
        :disabled="disabled"
      >
        <template v-slot:header>
          <a @click="showAddGP">
            <span> Add new... </span>
          </a>
        </template>
        <template v-slot="props">
          {{ props.option.fullName }}
        </template>
        <template v-slot:empty> {{ gpName ? "No Results for " + gpName : "No Results" }}</template>
      </b-autocomplete>
      <p v-if="gpPractice" class="column">
        {{ gpPractice }}<br />{{ gpTel }}
        <b-button v-if="!disabled" class="is-text is-small" @click="editGp">Edit</b-button>
      </p>
    </div>
  </b-field>
</template>

<script>
import DoctorEditDialog from "@/components/dialogs/DoctorEditDialog";

export default {
  props: ["doctorId", "disabled"],
  data() {
    return {
      gpName: "",
      selectedGp: null
    };
  },
  computed: {
    gpList() {
      return this.$store.getters["doctors/docs"];
    },
    filteredGPList() {
      const searchName = this.gpName ? this.gpName.toLowerCase() : null;
      if (!searchName) return this.gpList;
      const filtered = this.gpList.filter(option => {
        return option.fullName.toLowerCase().indexOf(searchName) >= 0;
      });
      // clear any selection if no match
      if (!filtered.length) this.$refs.autocomplete.setHovered(null);

      return filtered;
    },
    gpPractice() {
      return this.selectedGp?.practiceName;
    },
    gpTel() {
      return this.selectedGp?.workNumber ? "Tel: " + this.selectedGp.workNumber : "";
    }
  },
  watch: {
    doctorId(newValue) {
      this.selectGpFromId(newValue);
    }
  },
  async created() {
    await this.loadDocs();
  },
  methods: {
    blur() {
      // if we have entered something
      if (this.gpName) {
        // but no match, click new
        if (!this.filteredGPList.length) this.showAddGP();
        else this.selectGp(this.filteredGPList[0]); // select first
      }
    },
    newDoctor() {
      return { name: "", doctorId: null };
    },
    async loadDocs() {
      this.isLoading = true;
      await this.$store.dispatch("doctors/loadDocs");
      this.isLoading = false;
      this.selectGpFromId(this.doctorId);
    },
    selectGpFromId(doctorId) {
      // if gps loaded yet
      if (!this.gpList.length) return;
      this.selectedGp = this.gpList.find(gp => gp.doctorId === doctorId);
      if (!this.selectedGp && !doctorId) this.selectedGp = this.newDoctor();
      this.selectGp(this.selectedGp);
    },
    selectGp(doctor) {
      this.gpName = doctor ? doctor.fullName : "";
      this.gpPhone = doctor ? doctor.fullName : "";
      this.$emit("select", doctor?.doctorId);
    },
    showAddGP() {
      // if existing gp, then clear name to enter new one
      if (this.doctorId) {
        this.gpName = "";
        this.selectedGp = this.newDoctor();
      }
      this.$refs.autocomplete.setSelected(null);
      this.editGp();
    },
    editGp() {
      this.$buefy.modal.open({
        parent: this,
        component: DoctorEditDialog,
        hasModalCard: true,
        props: { gp: this.selectedGp, gpName: this.gpName },
        events: { selectDoc: this.selectGp }
      });
    }
  }
};
</script>
